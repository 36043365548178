/* eslint-disable */
import React, { PureComponent } from 'react';

import Helmet from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class Adult extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>{seo.title('Adult Content | Flowfo')}</Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>
          {/* DEBUT HTML WEBFLOW */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Tue Jul 30 2019 04:50:09 GMT+0000 (UTC) */}
          <meta charSet="utf-8" />
          <title>ADULT CONTENT - FLOWFO</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d3fcbe3ad57b849b972c719/css/adult-flowfo.webflow.6efa2a186.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div className="section">
            <div className="div-block-2">
              <h1 className="heading">Adult content policy</h1>
              <h2 className="conditionheader">Let's start</h2>
              <p className="paragraphcondition">
                These guidelines are a part of our Content policies.
                <br />
              </p>
              <h2 className="conditionheader">Adult content - NSFW</h2>
              <p className="paragraphcondition">
                NSFW means 'Not Safe For Work' meaning 'with adult content'. NSFW artworks are not for users under 18
                yo.
                <br />
                As we strive to create a community that is inclusive and diverse, we want to take into consideration our
                diverse user base of different ages and sensitivities. <br />
                We ask creators to flag their artwork as NSFW if their creations may be considered inappropriate for
                people under 18 years of age. <br />
                This may include nudity or discussion around sexuality.
                <br />
                We realize that the exact age of majority may change depending on where you are, but most places require
                a certain level of maturity to view these creations and engage in these activities.
                <br />
                Banners and covers wich identify artworks can't be NSFW because they are viewable in the public
                'Discover' page.
                <br />
                It is important to note that we are not trying to create an arbitrary framework of “right” or “wrong,”
                but rather giving people the tools to view only what they feel comfortable with.
              </p>
              <h2 className="conditionheader">Nudity</h2>
              <p className="paragraphcondition">
                You can post nude creations on Flowfo of both real and fictional subjects.
                <br />
                Artworks with mature themes should be marked as NSFW (Not Safe For Work). This means that you can not
                post nudity in public areas including your profile picture, banner, cover or ‘about’ section.
                <br />
                As a reminder, you should never post or share nude creations of any individual under the age of 18,
                including yourself.
                <br />
                Shotacon (for comics) is strictly forbidden on Flowfo.
                <br />
                As a strong commitment to child safety, we will work with law enforcement whenever we come across child
                exploitation material. <br />
                We understand that you may make a mix 18+ creations and other types of creations, and that is okay with
                us too but you have to declare your artwork as NSFW. Another solution is to open two different artworks,
                one NSFW, the other SFW.
              </p>
              <h2 className="conditionheader">Pornography</h2>
              <p className="paragraphcondition">
                Sexual services are strictly forbidden.
                <br />
                We don't allow pornographic material which we define as"real people engaging in sexual acts such as
                masturbation or sexual intercourse on camera." <br />
                We may consider exception for photographers having an evident artistic approach but you have to refer to
                us before creating your artwork.
              </p>
              <p className="paragraphcondition-copy">.</p>
            </div>
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* FIN HTML WEBFLOW */}
        </div>
      </>
    );
  }
}

export default Adult;
